.underlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  max-height: 56.5rem;
  width: 33.125rem;
  outline: none;
}

.headerContainer {
  border-bottom: 1px solid var(--slate-15);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
  position: relative;
  transition: box-shadow 0.2s ease-out;
  z-index: 1001;
}

.title {
  display: flex;
  align-items: center;
}

.title .icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.813rem;
  margin-left: 0;
}

.title .closeButton {
  border: none;
  background: transparent;
  width: fit-content;
  cursor: pointer;
  padding: 0;
  align-self: center;
  margin-left: auto;
  outline: none;
}

.title .closeIcon {
  color: var(--slate-darken-20);
  margin: 0;
  width: 0.875rem;
  height: 0.875rem;
}

.subtitle {
  margin-top: 0.5rem;
}

.scrollContainer {
  margin: 0 0.5rem;
  overflow: hidden;
}

.scrollContainer:hover {
  overflow-y: overlay;
}

.scrollContainer::-webkit-scrollbar {
  width: 8px;
}

.scrollContainer::-webkit-scrollbar-thumb {
  background: rgba(217, 217, 217, 1);
  border-radius: 8px;
}

.scrollContainer::-webkit-scrollbar-track,
.scrollContainer::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

.scrollContent {
  padding: 1.5rem 2rem;
}

.footerContainer {
  border-top: 1px solid var(--slate-15);
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2.5rem;
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  transition: box-shadow 0.2s ease-out;
  z-index: 1001;
}

.isScrollBoundary {
  box-shadow: none;
  transition: box-shadow 0.2s ease-out;
}

/*
Sometimes Chrome can run into issues where relative units aren't rounded properly
and instead sub-pixel values are used. This can lead to situations where scrolling
can stop a fraction of a pixel prior to the bottom so the bound intersection never
triggers. Giving them a height of at least 1px prevents them from being cut off.
 */
.bound {
  height: 1px;
}
