.container {
  width: 450px;
}

.typeSelector {
  margin-bottom: 24px;
}

.note {
  height: 100px;
  resize: none;
}

.wide {
  width: 100%;
}

.viewSelector {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.viewSelectorPane span {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 6px;
}

.viewSelector div {
  border: 2px solid var(--slate-05);
  text-align: center;
  padding: 8px;
  cursor: pointer;
  flex: 1;
}

.viewSelector .selected {
  border-color: var(--eden);
  background-color: var(--apple-10);
}

.viewSelector .selected:first-child {
  border-right-width: 2px;
}

.viewSelector .selected:last-child {
  border-left-width: 2px;
}

.viewSelector div:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right-width: 0;
}

.viewSelector div:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left-width: 0;
}

.inPersonPane {
  display: flex;
  justify-content: space-between;
}

.inPersonPane > span {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.modalWindow {
  width: fit-content;
}

.ticklerForm {
  margin-top: 23px;
}

.ticklerFormCheckboxLabel {
  display: flex;
  align-items: center;
  color: var(--slate);
  gap: 8px;
  width: fit-content;
}

.ticklerFormCheckbox {
  margin-left: -3.24px;
}

.ticklerFormFields {
  margin-top: 24px;
}
